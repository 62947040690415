import Vue from "vue";
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import store from './store/store'
import VueSlideoutPanel from 'vue2-slideout-panel';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import 'jquery-ui';
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import animated from 'animate.css';

export const CMS = 'https://cms.scriptics.net/';

gsap.registerPlugin(ScrollTrigger);
window.jQuery = $


Vue.component('Panel', require('../src/components/Menu/Panel').default);
Vue.component('CookiesPanel', require('../src/components/CookiesPanel').default);
Vue.component('Partners', require('../src/components/Home/Partners').default);
Vue.component('PanelHr', require('../src/components/Team/PanelHr').default);
Vue.component('PrivacyPolicyPanel', require('../src/components/PrivacyPolicyPanel').default);

Vue.config.productionTip = false;

Vue.use(Vuetify,
    { iconfont: "mdi", // 'md' || 'mdi' || 'fa' || 'fa4'
});
Vue.use(Vuetify);
Vue.use(VueSlideoutPanel);
Vue.use(animated);


Vue.mixin({
    created: function () {
        this.gsap = gsap;
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify: new Vuetify(),
    render: h => h(App)
}).$mount('#app');

